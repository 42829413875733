
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";


const supportList = [
    {
        text: ' +216 20 472 727',
        link: '#',
    },
    {
        text: '+216 21 646 503',
        link: '#',
    },
    {
        text: 'contact@arzaak.tn',
        link: '#',
    },
   
   
]
const socialList1 = [
    {
        link: 'https://www.facebook.com/arzaak.tn',
        iconName: 'icofont-facebook',
        className: 'rss',
    },
    {
        link: 'https://www.instagram.com/arzaak_tn',
        iconName: 'icofont-instagram',
        className: 'rss',
    }, 
    {
        link: 'https://www.tiktok.com/@arzaak.tn',
        iconName: "icofont-tiktok",
        className: 'rss',
    }, 
    {
        link: 'https://www.youtube.com/@Arzaak249?si=F7D4s8eH1XFylCn_',
        iconName: 'icofont-youtube',
        className: 'rss',
    }, 
 
]


const Footer = () => {
    const [t, i18n] = useTranslation("global");
    const handleChangeLanguage = (lang) => {
      i18n.changeLanguage(lang);
    };
    const newsTitle = `${t("footer.footer.1")}`;
const siteTitle = `${t("footer.footer.2")}`;
const useTitle = `${t("footer.footer.6")}`;
const socialTitle = `${t("footer.footer.3")}`;
const supportTitle =`${t("footer.footer.4")}`;


const siteList = [
    {
        text: `${t("footer.footer.5")}`,
        link: '#',
    },
   
]

const useList = [
  
    {
        text: `${t("footer.footer.7")}`,
        link: '#',
    },
    {
        text: `${t("footer.footer.8")}`,
        link: '/contact',
    },
  
    {
        text: `${t("footer.footer.9")}`,
        link: '#',
    },
    
    {
        text: `${t("footer.footer.11")}`,
        link: '#',
    },
    {
        text: `${t("footer.footer.12")}`,
        link: '#',
    },
]

const socialList = [
    {
        text: `${t("footer.footer.13")}`,
        link: '/course',
    },
    {
        text: `${t("footer.footer.18")}`,
        link: '#',
    },
    {
        text: `${t("footer.footer.15")}`,
        link: '/blog',
    },
    {
        text: `${t("footer.footer.16")}`,
        link: '/team',
    },
    {
        text: `${t("footer.footer.17")}`,
        link: '/session',
    },
]
    return (
        <div className="news-footer-wrap">
           
            
            <div className="news-letter">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="news-title">
                            <h3>{newsTitle}</h3>
                        </div>
                        <div className="news-form">
                            <form action="/">
                                <div className="nf-list">
                                    <input type="email" name="email" placeholder="Entrer votre Email" />
                                    <input type="submit" name="submit" value="S'inscrire" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <footer>
                <div className="footer-top padding-tb pt-0">
                    <div className="container">
                        <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{siteTitle}</h4>
                                            </div>
                                            <div className="content">
                                            <ul className="lab-ul">
                                                    {siteList.map((val, i) => (
                                                        <li key={i}>
                                                           <p style={{ color: 'white', fontSize: '15px' }}>{val.text}</p>
<br></br>
                                                            <img src="https://firebasestorage.googleapis.com/v0/b/arzaakdatabase.appspot.com/o/uploads%2F001.png?alt=media&token=a58495cd-9a1a-45b5-bb51-9d8d8dde132e" alt="Logo" style={{ height: '75px' }} />
                                                        </li>
                                                    ))}
                                                </ul>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{useTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {useList.map((val, i) => (
                                                        <li key={i} ><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{socialTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {socialList.map((val, i) => (
                                                        <li key={i}><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{supportTitle}</h4>
                                            </div>
                                            <div className="content">
                                            <ul className="lab-ul">
                                                {supportList.map((val, i) => (
                                                    <li key={i}>
                                                        {val.text.includes('@') ? (
                                                            <>
                                                                <FontAwesomeIcon icon={faEnvelope}  style={{ color: 'white' }}/> &nbsp;&nbsp;
                                                                <a href={`mailto:${val.text}`}>{val.text}</a>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <FontAwesomeIcon icon={faPhone}  style={{ color: 'white' }}/> &nbsp;&nbsp;
                                                                <a href={`tel:${val.text.replace(/\s+/g, '')}`}>{val.text}</a>
                                                            </>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                            <ul></ul>
                                            <ul className="lab-ul social-icons">
                                                                {socialList1.map((val, i) => (
                                                                   <li key={i} style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px', borderRadius: '50%' }}>

                                                                        <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                                                    </li>
                                                                ))}
                                                            </ul> 
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom style-2" >
                    <div className="container">
                        <div className="section-wrapper">
                            <p>&copy; 2024 <Link to="/">{t("footer.footer.19")}</Link> </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
 
export default Footer;