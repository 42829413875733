import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import {useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import myContext from "../ContextUser/Context";
import styles from "./header.module.css";
import { useTranslation } from "react-i18next";
import axios from 'axios';

const phoneNumber = "+216 20 472 727";
const address = "contact@arzaak.tn";

let socialList = [
  {
   
    iconName: "icofont-facebook-messenger",
    siteLink: "https://www.facebook.com/arzaak.tn",
  },
  {
    iconName: "icofont-instagram",
    siteLink: "https://www.instagram.com/arzaak_tn",
  },
  {
    iconName: "icofont-youtube",
    siteLink: "https://www.youtube.com/@Arzaak249?si=F7D4s8eH1XFylCn_",
  },
 
 
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const apiURL = process.env.REACT_APP_BACKEND_URL;
  
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${apiURL}/notifUser/notifications`);
        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();

    const eventSource = new EventSource(`${apiURL}/events`);

    eventSource.onmessage = (event) => {
      console.log('New event received:', event);
      const newNotification = JSON.parse(event.data);
      setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSource.close();
    };

    return () => {
      console.log('Closing EventSource');
      eventSource.close();
    };
  }, []);

  const toggleNotifications = () => {
    setShowNotifications((prevShowNotifications) => !prevShowNotifications);
  };

  const handleNotificationClick = (notificationId, courseId) => {
    window.location.href = `/course/${courseId}`;
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  const [bg, setBg] = useState("");
  const [clr, setClr] = useState("");
  const hadelStyle7 = () => {
    setBg("#f16126");
    setClr("white");
  };
  const hadelStyle7Reset = () => {
    setBg("");
    setClr("");
  };

  const { context, setContext } = useContext(myContext);
  const navigate = useNavigate();
  //

  //
  const handelDeconnect = () => {
    console.log("déconnexion user");
    console.log(context.connectedUser.googleConnect)
    console.log({context})
    if (context.connectedUser.googleConnect) {
      window.open(`${apiURL}/logOut`, "_self");
    } else {
      setContext({
        connectedUser: {},
        loginRegister: true,
        response: true,
      });
      navigate("/login");
    }
    localStorage.removeItem("Arzaaksession");
  };
 
  useEffect(() => {
    const fetchCart = async () => {
      try {
        const response = await axios.get(`${apiURL}/cart/${context.connectedUser._id}`);
        setCartItemsCount(response.data.items.length);
      } catch (error) {
        console.error("Erreur lors de la récupération du panier", error);
      }
    };
  
    fetchCart();
  }, [context.connectedUser._id]);

  useEffect(() => {
    const fetchCart = async () => {
      if (context.connectedUser && context.connectedUser._id) {
        console.log("connectedUser", context.connectedUser._id )
        try {
          const response = await axios.get(`${apiURL}/cart/${context.connectedUser._id}`);
          if (response.data && response.data.items) {
            setCartItemsCount(response.data.items.length);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération du panier", error);
        }
      } else {
        console.log("User ID is undefined, cart fetch skipped.");
      }
    };
  
    fetchCart();
  }, [context.connectedUser]);
  

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`} >
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
              </li>
              <li>
                <i className="icofont-email"></i> {address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li>
                <p> {t("header.navbar.6")}</p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink}>
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>

            <ul>
              <br></br>
              <a
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-offset="0,0"
              >
                {" "}
                <img src="https://firebasestorage.googleapis.com/v0/b/arzaakdatabase.appspot.com/o/networking.png?alt=media&token=c7df7057-16dd-4ecf-8344-e099d7014ad7" width={"20px"} />
                &nbsp; &nbsp; {t("header.navbar.7")}
              </a>
              <ul className="lab-ul dropdown-menu">
                <li onClick={() => handleChangeLanguage("fr")}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;{" "}
                  <img src="https://firebasestorage.googleapis.com/v0/b/arzaakdatabase.appspot.com/o/france.png?alt=media&token=7ecb0ec8-4968-4c18-860f-734f540b45bc" width={"20px"} />
                  &nbsp; &nbsp; {t("header.navbar.7.1")}
                </li>
                <li onClick={() => handleChangeLanguage("ar")}>
                  &nbsp;&nbsp;&nbsp;{" "}
                  <img src="https://firebasestorage.googleapis.com/v0/b/arzaakdatabase.appspot.com/o/tunisia.png?alt=media&token=9d411929-447d-4398-a85e-7bb563575068" width={"20px"} />
                  &nbsp; &nbsp; {t("header.navbar.7.2")}
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="header-bottom" style={{ height: "90px"}}>
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/arzaakdatabase.appspot.com/o/uploads%2F001.png?alt=media&token=a58495cd-9a1a-45b5-bb51-9d8d8dde132e"
                  alt="logo"
                  width={"70px"}
                />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li>
                    <NavLink to="/" className={styles["nav-link"]}>
                      {t("header.navbar.1")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/course" className={styles["nav-link"]}>
                      {t("header.navbar.2")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/event" className={styles["nav-link"]}>
                      {t("header.navbar.8")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/blog" className={styles["nav-link"]}>
                      {t("header.navbar.3")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/session" className={styles["nav-link"]}>
                      {t("header.navbar.4")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/contact" className={`${styles["nav-link"]}`}>
                      {t("header.navbar.5")}
                    </NavLink>
                  </li>
                 
                  
                  {!context.response && (
                    <>
                      <SkeletonTheme borderRadius="0.5rem">
                        <Skeleton width={220} height={45} className="mt-2" />
                      </SkeletonTheme>
                    </>
                  )}
                  {context.loginRegister && context.response && (
                    <>
                      <li>
                        {" "}
                        <Link to="/login" className="login">
                          <i className="icofont-user"></i>{" "}
                          <span>Connexion</span>{" "}
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/signup" className="signup">
                          <i className="icofont-users"></i>{" "}
                          <span>Inscription</span>{" "}
                        </Link>
                      </li>
                    </>
                  )}

                  {Object.keys(context.connectedUser).length !== 0 && (
                    <>
                      <li className="menu-item-has-children">
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-bs-offset="0,0"
                          className={styles["nav-link"]}
                        >
                          <img
                            className="me-2 mb-1 rounded-circle"
                            alt=""
                            height={30}
                            width={30}
                            src={context.connectedUser.image}
                          ></img>
                          Bienvenue <span>{context.connectedUser.nom}</span>
                        </a>
                        <ul className="lab-ul dropdown-menu">
                          <li>
                            <NavLink to="/profile"> Mon compte </NavLink>{" "}
                          </li>
                          <li
                            onClick={handelDeconnect}
                            name="btnDeconnexion"
                            className="py-2"
                            style={{
                              cursor: "pointer",
                              color: `${clr}`,
                              backgroundColor: `${bg}`,
                            }}
                            onMouseEnter={hadelStyle7}
                            onMouseLeave={hadelStyle7Reset}
                          >
                            {" "}
                            <span className="ms-3">Déconnexion </span>
                          </li>
                        </ul>

                      </li>
                      <li className="menu-item-has-children">
                        <a
                          href="#"
                          role="button"
                          onClick={toggleNotifications}
                          aria-haspopup="true"
                          aria-expanded={showNotifications}
                          data-bs-offset="0,0"
                          className={styles["nav-link"]}
                        >
                          🔔 <span className="count">{notifications.length}</span>
                        </a>
                        {showNotifications && (
                          <ul className="lab-ul dropdown-menu">
                            {notifications.map((notification, index) => (
                              <li key={index}>
                                <button onClick={() => handleNotificationClick(notification._id, notification.courseId)}>
                                  {notification.message}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>


                      <li>
  <NavLink to={`/panier/${context.connectedUser._id}`}>🛒
  <span className="count">{cartItemsCount}</span>
  </NavLink>{" "}
</li>
                    </>
                  )}
                </ul>
              </div>

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
