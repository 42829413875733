
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';
import './App.css'
import axios from "axios";
import { toast } from "react-toastify";
import "react-loading-skeleton/dist/skeleton.css";

import myContext from "./component/ContextUser/Context" ;

import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./component/page/404";
import AboutPage from "./component/page/about";


import ContactPage from "./component/page/contact";
import InstructorPage from "./component/page/instructor";
import SearchNone from "./component/page/search-none";
import SearchPage from "./component/page/search-page";
import ShopPage from "./component/page/shop";
import ShopDetails from "./component/page/shop-single";
import TeamSingle from "./component/page/team-single";
import Header from "./component/layout/header";
import Chat from "./component/page/Chat";
//
import CircleLoader from "react-spinners/CircleLoader";
import { lazy,Suspense,useEffect,useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = lazy(async()=>import("./component/page/login"));
const SignupPage = lazy(async()=>import("./component/page/signup"));
const Home=lazy(async()=>import("./component/page/home"));
const CoursePage=lazy(async()=>import("./component/page/course"));
const EventPage=lazy(async()=>import("./component/page/event"));
const CourseSingle=lazy(async()=>import("./component/page/course-single"));
const ForgetPass = lazy(async () => import("./component/page/forgetpass"));
const SessionPriveePage=lazy(async()=>import("./component/page/session-privée"));
const BlogPage = lazy(async () => import("./component/page/blog"));
const BlogSingle = lazy(async () => import("./component/page/blog-single"));
const EventSingle = lazy(async () => import("./component/page/event-single"));
const TeamPage = lazy(async () => import("./component/page/team"));
const ProfilePage = lazy(async () => import("./component/page/profile"));
const RealisationPage = lazy(async () => import("./component/page/realisation"));
const ReclamationPage = lazy(async () => import("./component/page/reclamation"));
const CourseDetailPaid=lazy(async()=>import("./component/page/course-detail-paid"));
const QuizPage=lazy(async()=>import("./component/page/quiz"));
const ResultatQuizPage=lazy(async()=>import("./component/page/resultatQuiz"));
const CartPage=lazy(async()=>import("./component/page/cart-page"));
const ModePaiementPage=lazy(async()=>import("./component/page/mode-paiement"));

const apiURL = process.env.REACT_APP_BACKEND_URL;
function App() {
	//créatin d'un contexte pur connaitre s'il ya un user connecté ou non:

	const [context, setContext] = useState({
    connectedUser: {},
    loginRegister: true,
    response: true,
  });

	//authentification token :
	// useEffect(()=>{
  //   const token = JSON.parse(localStorage.getItem("Arzaaksession"));
  //   console.log("token" ,token);    
	// 	if (token) {
  //     // Configurez Axios pour inclure automatiquement le token dans les headers
  //     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //     //

  //     axios
  //       .get(`${apiURL}/users/authentification-token`)
  //       .then((res) => {
          
  //         console.log(res);
  //         setContext({
  //           connectedUser: res.data.user,
  //           loginRegister: false,
  //           response: true,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err.response);
  //         //erreur serveur:
  //         if (err.response.status === 500) {
  //           toast.error(err.response.data.msg);
  //           setContext({
  //             connectedUser: {},
  //             loginRegister: true,
  //             response: false,
  //           });
  //         } else {
  //           setContext({
  //             connectedUser: {},
  //             loginRegister: true,
  //             response: true,
  //           });
  //         }
  //       });
  //   } else {
	// 		axios
  //       .get(`${apiURL}/loginSuccessGoogle`, {
  //         withCredentials: true,
  //       })
  //       .then((res) => {
  //         console.log(res);
	// 				 setContext({
  //              connectedUser: res.data.user,
  //              loginRegister: false,
  //              response: true,
  //            });
  //        localStorage.setItem("Arzaaksession", JSON.stringify(res.data.token));
  //       })
  //       .catch((err) => {
  //         console.log(err);
	// 				 setContext({
  //            connectedUser: {},
  //            loginRegister: true,
  //            response: true,
  //          });
  //       });
			
	// 	}
    

  // },[])

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("Arzaaksession"));
  
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  
      axios.get(`${apiURL}/users/authentification-token`)
        .then((res) => {
          console.log(res.data);
          setContext({
            connectedUser: res.data.user,
            loginRegister: false,
            response: true,
          });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 500) {
            toast.error(err.response.data.msg);
            setContext({
              connectedUser: {},
              loginRegister: true,
              response: false,
            });
          }
        });
    }
  }, []);


	//
	return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ fontSize: "13px",fontWeight:'bold' }}
        theme="light"
      />
      <BrowserRouter>
        <myContext.Provider value={{ context, setContext }}>
          <ScrollToTop />
          <Header />
          <Chat />
          <div style={{ minHeight: "100vh" }}>
            <Suspense
              fallback={
                <div className="text-center">
                  <CircleLoader
                    size={350}
                    color="#f16126"
                    cssOverride={{
                      marginTop: "230px",
                      display: "inline-block",
                    }}
                  ></CircleLoader>
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="course" element={<CoursePage />} />
                <Route path="event" element={<EventPage />} />
                <Route path="/event/:id" element={<EventSingle />} />
                <Route path="/course/:id" element={<CourseSingle />} />
                <Route path="/courseDetail/:id" element={<CourseDetailPaid />} />
                <Route path="blog" element={<BlogPage />} />
                <Route path="/blog/:id" element={<BlogSingle />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="team" element={<TeamPage />} />
                <Route path="session" element={<SessionPriveePage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="/quiz/:id" element={<QuizPage />} />
                <Route path="/resultatQuiz/:idUser/:idQuiz" element={<ResultatQuizPage />} />
                <Route path="team-single" element={<TeamSingle />} />
                <Route path="instructor" element={<InstructorPage />} />
                <Route path="shop" element={<ShopPage />} />
                <Route path="shop-single" element={<ShopDetails />} />
                <Route path="realisation" element={<RealisationPage />} />
                <Route path="reclamation" element={<ReclamationPage />} />
                <Route path="/panier/:id" element={<CartPage />} />
                <Route path="/paiement" element={<ModePaiementPage />} />
                <Route path="search-page" element={<SearchPage />} />
                <Route path="search-none" element={<SearchNone />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route path="forgetpass" element={<ForgetPass />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Suspense>
          </div>
        
        </myContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
