
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";
import { useTranslation } from "react-i18next";


const Student = () => {
    const [t, i18n] = useTranslation("global");
    const handleChangeLanguage = (lang) => {
      i18n.changeLanguage(lang);
    };
    const subTitle = `${t("student.student.1")}`;
const title = `${t("student.student.2")}`;
const btnText = `${t("student.student.6")}`;

const studentList = [
    {
       
       
        name: `${t("student.student.3")}`,
     
        desc: `${t("student.student.4")}`,
         desc2: `${t("student.student.5")}`
    },
   
]


    return (
        <div className="student-feedbak-section padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        <div className="col">
                            <div className="sf-left">
                                <div className="sfl-thumb">
                                    
                                  
                                      <video controls style={{ width : "610px" , height : "550px"  , marginTop : "-100px",  marginBottom : "10px"}}>
                                        <source src="assets/videos/sessionPrivee.mp4" type="video/mp4" />
                                        </video>  
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                      </div>
                                                <div className="sftl-content">
                                                    <Link to="/"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                    
                                                </div>
                                            </div>
                                            <div className="sft-right">
                                                <Rating />
                                            </div>
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                            <p>{val.desc2}</p>
                                        </div>
                                        <div className="text-center mt-5">
            <Link to="/session" className="lab-btn"><span>{btnText}</span></Link>
            <br></br>
          </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Student;