
import CountUp from 'react-countup';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";


const Achievement = () => {
    const apiURL = process.env.REACT_APP_BACKEND_URL;
    const [t, i18n] = useTranslation("global");
    const handleChangeLanguage = (lang) => {
      i18n.changeLanguage(lang);
    };
    const subTitle = `${t("achievement.achievement.1")}`;
const title = `${t("achievement.achievement.2")}`;


const achievementList = [
    {
        count: '30',
        desc: `${t("achievement.achievement.3")}`,
    },
    {
        count: '3084',
        desc: `${t("achievement.achievement.4")}`,
    },
    {
        count: '330',
        desc: `${t("achievement.achievement.5")}`,
    },
    {
        count: '2300',
        desc: `${t("achievement.achievement.13")}`,
    },
]

const achieveList = [
    {
        imgUrl: 'assets/images/achive/01.png',
        imgAlt: 'achive thumb rajibraj91 rajibraj',
        title: `${t("achievement.achievement.7")}`,
        desc: `${t("achievement.achievement.9")}`,
         btnText: `${t("achievement.achievement.10")}`,
        siteLink: '/team',
    },
    {
        imgUrl: 'assets/images/achive/02.png',
        imgAlt: 'achive thumb rajibraj91 rajibraj',
        title:`${t("achievement.achievement.8")}`,
        desc: `${t("achievement.achievement.11")}`,
        btnText: `${t("achievement.achievement.12")}`,
        siteLink: '/signup',
    },
]

    const [categoriesCount, setCategoriesCount] = useState(0);
    const [coursesCount, setCoursesCount] = useState(0);
    const [trainersCount, setTrainersCount] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoriesResponse = await axios.get(`${apiURL}/categorys/count`);
                const coursesResponse = await axios.get(`${apiURL}/courses/count`);
                const trainersResponse = await axios.get(`${apiURL}/trainer/count`);

                setCategoriesCount(categoriesResponse.data.count);
                setCoursesCount(coursesResponse.data.count);
                setTrainersCount(trainersResponse.data.count);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="achievement-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="counter-part mb-4">
                        <div className="row g-4 row-cols-lg-4 row-cols-sm-2 row-cols-1 justify-content-center">
                           
                                <div className="col">
                                    <div className="count-item">
                                        <div className="count-inner">
                                            <div className="count-content">
                                            <h2><span className="count">{categoriesCount}</span><span>+</span></h2>
                                            <p>{t("achievement.achievement.3")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                <div className="count-item">
                                    <div className="count-inner">
                                        <div className="count-content">
                                            <h2><span className="count">{coursesCount}</span><span>+</span></h2>
                                            <p>{t("achievement.achievement.4")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="count-item">
                                    <div className="count-inner">
                                        <div className="count-content">
                                        <h2><span className="count">{trainersCount}</span><span>+</span></h2>
                                            <p>{t("achievement.achievement.13")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="achieve-part">
                        <div className="row g-4 row-cols-1 row-cols-lg-2">
                            {achieveList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="achieve-item">
                                        <div className="achieve-inner">
                                            <div className="achieve-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="achieve-content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                                <a href={val.siteLink} className="lab-btn"><span>{val.btnText}</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Achievement;