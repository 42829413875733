import React from "react";

const Pagination = ({ coursesPerPage, totalCourses, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalCourses / coursesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="default-pagination lab-ul">
   
      <li>
        <a href="#" onClick={() => paginate(1)}>
          <i className="icofont-rounded-left"></i>
        </a>
      </li>
    
      {pageNumbers.map((number) => (
        <li key={number}>
          <a
            href="#"
            onClick={() => paginate(number)}
            className="active"
          >
            {number}
          </a>
        </li>
      ))}
     
      <li>
        <a
          href="#"
          onClick={() => paginate(Math.ceil(totalCourses / coursesPerPage))}
        >
          <i className="icofont-rounded-right"></i>
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
