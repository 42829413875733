import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import global_fr from './translations/fr/global.json';
import global_ar from './translations/ar/global.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import '././assets/css/icofont.min.css';
import '././assets/css/animate.css';
import '././assets/css/style.min.css';
import { I18nextProvider } from 'react-i18next';
i18next.init({
  interpolation : { escapeValue : false },
  lng: "fr",
  resources : {
   fr: {
    global: global_fr,
   },
   ar: {
    global: global_ar,
   },
  },

  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
  <App />
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
